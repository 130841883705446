import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/Index'
  },
  {
    path: '/Index',
    name: 'Index',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Index.vue'),
    children: [
      {
        path: '/Index/SearchList',
        meta: { cnName: '搜索结果', isSearchPage: true },
        component: () =>
          import(
            /* webpackChunkName: "SearchList" */ '../views/SecondRouter/NewsCenter/SearchList.vue'
          )
      }
    ]
  },
  {
    path: '/BusinessSection',
    name: 'BusinessSection',
    component: () =>
      import(
        /* webpackChunkName: "BusinessSection" */ '../views/BusinessSection.vue'
      ),
    children: [
      {
        path: '/BusinessSection',
        redirect: '/BusinessSection/Land'
      },
      {
        path: '/BusinessSection/Land',
        meta: { cnName: '房产开发' },
        component: () =>
          import(
            /* webpackChunkName: "Land" */ '../views/SecondRouter/BusinessSection/Land.vue'
          )
      },
      {
        path: '/BusinessSection/LandDetail',
        meta: { cnName: '房产开发详情' },
        component: () =>
          import(
            /* webpackChunkName: "LandDetail" */ '../views/SecondRouter/BusinessSection/LandDetail.vue'
          )
      },
      {
        path: '/BusinessSection/Building',
        meta: { cnName: '建材贸易' },
        component: () =>
          import(
            /* webpackChunkName: "Building" */ '../views/SecondRouter/BusinessSection/Building.vue'
          )
      },
      {
        path: '/BusinessSection/Estate',
        meta: { cnName: '物业商管' },
        component: () =>
          import(
            /* webpackChunkName: "Estate" */ '../views/SecondRouter/BusinessSection/Estate.vue'
          )
      },
      {
        path: '/BusinessSection/SearchList',
        meta: { cnName: '搜索结果', isSearchPage: true },
        component: () =>
          import(
            /* webpackChunkName: "SearchList" */ '../views/SecondRouter/NewsCenter/SearchList.vue'
          )
      }
    ]
  },
  {
    path: '/HuaduCollege',
    name: 'HuaduCollege',
    component: () =>
      import(
        /* webpackChunkName: "HuaduCollege" */ '../views/HuaduCollege.vue'
      ),
    children: [
      {
        path: '/HuaduCollege',
        redirect: '/HuaduCollege/College'
      },
      {
        path: '/HuaduCollege/College',
        meta: { cnName: '学院简介' },
        component: () =>
          import(
            /* webpackChunkName: "College" */ '../views/SecondRouter/HuaduCollege/College.vue'
          )
      },
      {
        path: '/HuaduCollege/Train',
        meta: { cnName: '培训动态' },
        component: () =>
          import(
            /* webpackChunkName: "Train" */ '../views/SecondRouter/HuaduCollege/Train.vue'
          )
      },
      {
        path: '/HuaduCollege/TrainDetail',
        meta: { cnName: '培训动态', hideTab: true },
        component: () =>
          import(
            /* webpackChunkName: "TrainDetail" */ '../views/SecondRouter/NewsCenter/NewsDetail.vue'
          )
      },
      {
        path: '/HuaduCollege/SearchList',
        meta: { cnName: '搜索结果', isSearchPage: true },
        component: () =>
          import(
            /* webpackChunkName: "SearchList" */ '../views/SecondRouter/NewsCenter/SearchList.vue'
          )
      }
    ]
  },
  {
    path: '/IntoHuadu',
    name: 'IntoHuadu',
    component: () =>
      import(/* webpackChunkName: "IntoHuadu" */ '../views/IntoHuadu.vue'),
    children: [
      {
        path: '/IntoHuadu',
        redirect: '/IntoHuadu/Group'
      },
      {
        path: '/IntoHuadu/Develop',
        meta: { cnName: '发展历程' },
        component: () =>
          import(
            /* webpackChunkName: "Develop" */ '../views/SecondRouter/IntoHuadu/Develop.vue'
          )
      },
      {
        path: '/IntoHuadu/Company',
        meta: { cnName: '企业文化' },
        component: () =>
          import(
            /* webpackChunkName: "Company" */ '../views/SecondRouter/IntoHuadu/Company.vue'
          )
      },
      {
        path: '/IntoHuadu/Honor',
        meta: { cnName: '企业荣誉' },
        component: () =>
          import(
            /* webpackChunkName: "Honor" */ '../views/SecondRouter/IntoHuadu/Honor.vue'
          )
      },
      {
        path: '/IntoHuadu/Group',
        name: 'Group',
        meta: { cnName: '集团概况' },
        component: () =>
          import(
            /* webpackChunkName: "Group" */ '../views/SecondRouter/IntoHuadu/Group.vue'
          )
      },
      {
        path: '/IntoHuadu/World',
        meta: { cnName: '社会公益' },
        component: () =>
          import(
            /* webpackChunkName: "Group" */ '../views/SecondRouter/IntoHuadu/World.vue'
          )
      },
      {
        path: '/IntoHuadu/WorldDetail/:id',
        name: 'WorldDetail',
        meta: { cnName: '公益详情', hideTab: true },
        component: () =>
          import(
            /* webpackChunkName: "world_detail" */ '../views/SecondRouter/IntoHuadu/WorldDetail.vue'
          )
      },
      {
        path: '/IntoHuadu/Organization',
        meta: { cnName: '组织架构' },
        component: () =>
          import(
            /* webpackChunkName: "Organization" */ '../views/SecondRouter/IntoHuadu/Organization.vue'
          )
      },
      {
        path: '/IntoHuadu/SearchList',
        meta: { cnName: '搜索结果', isSearchPage: true },
        component: () =>
          import(
            /* webpackChunkName: "SearchList" */ '../views/SecondRouter/NewsCenter/SearchList.vue'
          )
      }
    ]
  },
  {
    path: '/JoinHuadu',
    name: 'JoinHuadu',
    component: () =>
      import(/* webpackChunkName: "JoinHuadu" */ '../views/JoinHuadu.vue'),
    children: [
      {
        path: '/JoinHuadu',
        redirect: '/JoinHuadu/Job'
      },
      {
        path: '/JoinHuadu/People',
        meta: { cnName: '人才理念' },
        component: () =>
          import(
            /* webpackChunkName: "People" */ '../views/SecondRouter/JoinHuadu/People.vue'
          )
      },
      {
        path: '/JoinHuadu/Job',
        meta: { cnName: '招聘动态' },
        component: () =>
          import(
            /* webpackChunkName: "Job" */ '../views/SecondRouter/JoinHuadu/Job.vue'
          )
      },
      {
        path: '/JoinHuadu/Contact',
        meta: { cnName: '联系我们' },
        component: () =>
          import(
            /* webpackChunkName: "Staff" */ '../views/SecondRouter/JoinHuadu/Contact.vue'
          )
      },
      {
        path: '/JoinHuadu/SearchList',
        meta: { cnName: '搜索结果', isSearchPage: true },
        component: () =>
          import(
            /* webpackChunkName: "SearchList" */ '../views/SecondRouter/NewsCenter/SearchList.vue'
          )
      }
    ]
  },
  {
    path: '/NewsCenter',
    name: 'NewsCenter',
    component: () =>
      import(/* webpackChunkName: "NewsCenter" */ '../views/NewsCenter.vue'),
    children: [
      {
        path: '/NewsCenter',
        redirect: '/NewsCenter/News'
      },
      {
        path: '/NewsCenter/News',
        meta: { cnName: '集团新闻' },
        component: () =>
          import(
            /* webpackChunkName: "News" */ '../views/SecondRouter/NewsCenter/News.vue'
          )
      },
      {
        path: '/NewsCenter/NewsDetail',
        meta: { cnName: '集团新闻', hideTab: true },
        component: () =>
          import(
            /* webpackChunkName: "NewsDetail" */ '../views/SecondRouter/NewsCenter/NewsDetail.vue'
          )
      },
      {
        path: '/NewsCenter/NewsDetailStaff',
        meta: { cnName: '员工天地', hideTab: true },
        component: () =>
          import(
            /* webpackChunkName: "NewsDetail" */ '../views/SecondRouter/NewsCenter/NewsDetail.vue'
          )
      },
      {
        path: '/NewsCenter/Staff',
        meta: { cnName: '员工天地' },
        component: () =>
          import(
            /* webpackChunkName: "Staff" */ '../views/SecondRouter/NewsCenter/Staff.vue'
          )
      },
      {
        path: '/NewsCenter/SearchList',
        meta: { cnName: '搜索结果', isSearchPage: true },
        component: () =>
          import(
            /* webpackChunkName: "SearchList" */ '../views/SecondRouter/NewsCenter/SearchList.vue'
          )
      }
    ]
  },
  {
    path: '/VideoCenter',
    name: 'VideoCenter',
    component: () =>
      import(/* webpackChunkName: "VideoCenter" */ '../views/VideoCenter.vue'),
    children: [
      {
        path: '/VideoCenter',
        component: () =>
          import(
            /* webpackChunkName: "Figure" */ '../views/SecondRouter/VideoCenter/Figure.vue'
          )
      },
      // {
      //   path: '/VideoCenter/Figure',
      //   meta: { cnName: '' },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Figure" */ '../views/SecondRouter/VideoCenter/Figure.vue'
      //     )
      // },
      {
        path: '/VideoCenter/SearchList',
        meta: { cnName: '搜索结果', isSearchPage: true },
        component: () =>
          import(
            /* webpackChunkName: "SearchList" */ '../views/SecondRouter/NewsCenter/SearchList.vue'
          )
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
