import axios from 'axios'
const ERR_OK = 1
axios.defaults.headers.post['Content-Type'] = 'application/x-www-urlencoded'
axios.defaults.baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://beta.huadu.114nz.com/'
    : 'https://www.huadujt.com/'
// : 'http://10.12.180.180'

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export function get(url, params) {
  return axios
    .get(url, params)
    .then((res) => {
      if (res.data.code === ERR_OK) {
        return res.data.data
      } else {
        return res.data
      }
    })
    .catch((e) => {
      console.log('e', e)
    })
}

export function post(url, params) {
  return axios
    .post(url, { params })
    .then((res) => {
      if (res.data.code === ERR_OK) {
        return res.data.data
      }
    })
    .catch((e) => {
      console.log('e', e)
    })
}
