export const nav = [
  {
    title: '首页',
    path: '/Index',
    children: []
  },
  {
    title: '走进华都',
    path: '/IntoHuadu',
    children: [
      { name: '集团概况', path: '/IntoHuadu/Group' },
      { name: '组织架构', path: '/IntoHuadu/Organization' },
      { name: '企业文化', path: '/IntoHuadu/Company' },
      { name: '发展历程', path: '/IntoHuadu/Develop' },
      { name: '企业荣誉', path: '/IntoHuadu/Honor' },
      { name: '社会公益', path: '/IntoHuadu/World' }
    ]
  },
  {
    title: '业务板块',
    path: '/BusinessSection',
    children: [
      { name: '房产开发', path: '/BusinessSection/Land' },
      { name: '贸易建材', path: '/BusinessSection/building' },
      { name: '物业商管', path: '/BusinessSection/Estate' }
    ]
  },
  {
    title: '新闻中心',
    path: '/NewsCenter',
    children: [
      { name: '集团新闻', path: '/NewsCenter/News' },
      { name: '员工天地', path: '/NewsCenter/Staff' }
    ]
  },
  {
    title: '影像华都',
    path: '/VideoCenter',
    children: [
      // { name: '形象展示', path: '/VideoCenter/Figure' },
      // { name: '其他', path: '/VideoCenter/Other' }
    ]
  },
  {
    title: '华都学院',
    path: '/HuaduCollege',
    children: [
      { name: '学院简介', path: '/HuaduCollege/College' },
      { name: '培训动态', path: '/HuaduCollege/Train' }
    ]
  },
  {
    title: '加入华都',
    path: '/JoinHuadu',
    children: [
      // { name: '人才理念', path: '/JoinHuadu/People' },
      { name: '招聘动态', path: '/JoinHuadu/Job' },
      { name: '联系我们', path: '/JoinHuadu/Contact' }
    ]
  }
]
