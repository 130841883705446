<template>
  <footer class="site-footer">
    <div class="site-footer__middle">
      <div class="container">
        <div class="site-footer__middle-inner">
          <div class="row">
            <div
              class="col-xl-10 col-lg-10 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div
                class="footer-widget__column footer-widget__links clearfix link"
              >
                <ul
                  class="footer-widget__links-list list-unstyled clearfix"
                  v-for="item in navItems"
                  :key="item.title"
                >
                  <li class="first-nav-li">
                    <router-link tag="h5" :to="item.path">{{
                      item.title
                    }}</router-link>
                    <!-- <h5>{{ item.title }}</h5> -->
                  </li>
                  <li v-for="child in item.children" :key="child.title">
                    <router-link tag="a" :to="child.path">{{
                      child.name
                    }}</router-link>
                    <!-- <a href="services.html">{{ child.name }}</a> -->
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="col-xl-2 col-lg-2 wow fadeInUp text-center"
              data-wow-delay="300ms"
            >
              <img
                :src="require('@/assets/qrcode.png')"
                class="qrcode img-thumbnail"
              />
              <div class="qrcode-text text-center">微信公众号</div>
            </div>
          </div>
          <div class="row">
            <a class="col-xl-12 text-center text-white icp">
              <div>
                Copyright @ 2022 华都集团版权所有
                <a href="https://beian.miit.gov.cn/" target="_blank"
                  >浙ICP备2020044898号</a
                >
              </div>
              <a href="https://www.beian.gov.cn/" target="_blank"
                >浙公网安备33010802011709号</a
              >
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="site-footer__bottom" v-if="false">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="site-footer__bottom-inner">
              <p class="site-footer__bottom-text">
                © Copyrights, <span class="dynamic-year"></span>
                <a href="http://www.bootstrapmb.com/">Layerdrops.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
import { ref } from 'vue'
import { nav } from '@/localData.js'
const navItems = ref(nav)
</script>
<style lang="stylus" scoped>
.site-footer
  background url('../assets/22.png') no-repeat
  background-size cover
  background-position center center
  .link
    & > ul
      width 14.27% !important
      @media screen and (max-width 768px)
        width auto !important
        padding 0 15px
        & > li
          display none
          &:first-child
            display:block
  .footer-widget__newsletter
    background none !important
  .footer-widget__links-list
    @media screen and (max-width 768px){
      text-align center
    }
    li
      a
        color #fff
        @media screen and (max-width 768px){
          margin-bottom 0
        }
  .qrcode
    width 150px
    height 150px
    padding 2px
    background #fff
  .qrcode-text
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #FFFFFF;
    margin-top 20px
  .icp
    font-size 12px
    line-height 1.2
    text-decoration none
    & > div
      &:hover
        text-decoration none
      &:first-child
        margin-bottom 14px
    a
      color: #FFFFFF;
      text-decoration none

  .site-footer__middle
    @media screen and (max-width 768px)
      padding 20px 0
  .footer-widget__links
    @media screen and (max-width 768px)
      margin-bottom 10px
</style>
