<template>
  <teleport to="body">
    <div
      class="search-model"
      @click.prevent="hideModel"
      v-show="showSearchModel"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-xxl-12 col-xl-12">
            <div class="input-group mb-3 input-group-lg box">
              <input
                type="text"
                class="form-control"
                placeholder="输入搜索内容"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                v-model="searchValue"
                @keyup.enter="search"
                id="searchInput"
              />
              <span
                class="input-group-text"
                id="basic-addon2"
                @click.prevent="search"
              >
                <i class="bi bi-search" id="bi" style="color: #fff"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script setup>
import { ref, defineEmits, defineProps, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { get } from '@/server/http'
const searchValue = ref('')
const router = useRouter()
const route = useRoute()
const props = defineProps({
  showSearchModel: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:showSearchModel', 'searchSuccess'])
const hideModel = (e) => {
  if (
    e.target.id === 'searchInput' ||
    e.target.id === 'basic-addon2' ||
    e.target.id === 'bi'
  ) {
    return
  }
  emit('update:showSearchModel', false)
}

const search = () => {
  fetSearch(searchValue.value)
}

watch(
  () => props.showSearchModel,
  (newVal) => {
    if (!newVal) {
      searchValue.value = ''
    }
  }
)

watch(
  () => searchValue.value,
  () => {
    wait.value = false
  }
)

const wait = ref(false)
const fetSearch = (keywords) => {
  get(`/api/news/search?keywords=${keywords}&page=1&pagesize=10`).then(
    (res) => {
      if (res.code === 210) {
        if (wait.value) {
          return
        }
        ElMessage({ type: 'error', message: res.msg })
        wait.value = true
        setTimeout(() => {
          wait.value = false
        }, 3000)
      } else {
        emit('update:showSearchModel', false)
        emit('searchSuccess', true)
        const oneLeverPath = route.matched[0].path
        router.push({
          path: `${oneLeverPath}/searchList`,
          query: { keywords: keywords }
        })
      }
    }
  )
}
</script>
<style lang="stylus" scoped>
.search-model
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  background rgba(32, 155, 215, .7)
  z-index 2001
  .box
    position absolute
    top 50%
    left 50%
    width 560px
    transform translate(-50%,-50%)
    max-width 90%
    .input-group-text
      cursor pointer
      background rgb(32, 155, 215)
      border none
.form-control
  &:focus
    outline none
    box-shadow none
    border-color #ced4da
</style>
