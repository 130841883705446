<template>
  <header class="main-header clearfix bg-white">
    <div class="hot-line text-end w-100">
      <span class="toplink"><a :href="toplink">阳光招采</a></span>热线：0571-8888 8888
    </div>
    <nav class="main-menu clearfix container-fluid d-flex">
      <div class="left" style="flex: 1">
        <div class="main-menu-wrapper__logo">
          <img src="@/assets/1.png" />
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-xxl-12 col-xl-12 header-main position-relative mobile"
          >
            <div class="main-menu-wrapper">
              <div class="main-menu-wrapper__main-menu">
                <!-- <a class="mobile-nav__toggler"
                  ><i class="bi bi-list" style="font-size: 2rem"></i
                ></a> -->

                <ul class="main-menu__list">
                  <li class="dropdown" v-for="nav in navRef" :key="nav.title">
                    <router-link
                      tag="a"
                      :to="nav.path"
                      class="text-decoration-none"
                    >
                      {{ nav.title }}
                      <i
                        class="bi bi-chevron-down"
                        v-if="nav.children.length !== 0"
                      ></i
                    ></router-link>
                    <ul v-if="nav.children.length !== 0">
                      <li v-for="child in nav.children" :key="child.name">
                        <router-link
                          :to="child.path"
                          tag="a"
                          class="text-decoration-none"
                          >{{ child.name }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a class="mobile-search">
                      <i
                        class="bi bi-search"
                        style="color: #fff; font-size: 20px"
                        id="showSearch"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right" style="flex: 1">
        <a class="mobile-nav__toggler"
          ><i class="bi bi-list" style="font-size: 2rem"></i
        ></a>
        <div class="main-menu-wrapper__right">
          <div class="main-menu-wrapper__call">
            <div class="main-menu-wrapper__call-number">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="30"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
                @click="showSearchModel = true"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
              <SearchModelVue
                v-model:showSearchModel="showSearchModel"
                @searchSuccess="searchSuccess"
              ></SearchModelVue>
            </div>
          </div>
          <div class="main-menu-wrapper__search-box">
            <a
              href="#"
              class="main-menu-wrapper__search search-toggler icon-magnifying-glass"
            ></a>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <div class="stricky-header stricked-menu main-menu">
    <div class="sticky-header__content"></div>
    <!-- /.sticky-header__content -->
  </div>
  <!-- /.stricky-header -->
  <div class="mobile-nav__wrapper">
    <div class="mobile-nav__overlay mobile-nav__toggler"></div>
    <!-- /.mobile-nav__overlay -->
    <div class="mobile-nav__content">
      <span class="mobile-nav__close mobile-nav__toggler">
        <i class="bi bi-list" style="font-size: 2rem; color: #fff"></i>
      </span>
      <div class="logo-box">
        <a href="index.html" aria-label="logo image"
          ><img src="@/assets/logo2.png" width="155" alt=""
        /></a>
      </div>
      <!-- /.logo-box -->
      <div class="mobile-nav__container">
        <!-- <ul class="main-menu__list">
          <li class="dropdown" v-for="(nav, index) in navRef" :key="nav.title">
            <a href="index.html" class="text-decoration-none"
              >{{ nav.title }}
              <button
                aria-label="dropdown toggler"
                class=""
                @click.prevent="showChildMenu(index)"
              >
                <i class="fa fa-angle-down"></i>
              </button>
            </a>
            <ul
              v-if="nav.children.length !== 0 && childMenuShowIndex === index"
            >
              <li v-for="child in nav.children" :key="child.name">
                <a href="index.html" class="text-decoration-none">{{
                  child.name
                }}</a>
              </li>
            </ul>
          </li>
        </ul> -->
      </div>
      <!-- /.mobile-nav__container -->

      <ul class="mobile-nav__contact list-unstyled" v-if="false">
        <li>
          <i class="fa fa-envelope"></i>
          <a href="mailto:needhelp@packageName__.com">needhelp@mibooz.com</a>
        </li>
        <li>
          <i class="fa fa-phone-alt"></i>
          <a href="">666 888 0000</a>
        </li>
      </ul>
      <!-- /.mobile-nav__contact -->
      <div class="mobile-nav__top">
        <div class="mobile-nav__social">
          <a href="#" class="fab fa-twitter"></a>
          <a href="#" class="fab fa-facebook-square"></a>
          <a href="#" class="fab fa-pinterest-p"></a>
          <a href="#" class="fab fa-instagram"></a>
        </div>
        <!-- /.mobile-nav__social -->
      </div>
      <!-- /.mobile-nav__top -->
    </div>
    <!-- /.mobile-nav__content -->
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import SearchModelVue from './SearchModel.vue'
import $ from 'jquery'
import { nav } from '@/localData.js'
import { getIndex } from '@/server/index.js'

const navRef = ref(nav)
const showSearchModel = ref(false)
const searchSuccess = () => {
  // 搜索成功后 ,隐藏移动端侧边栏
  $('.mobile-nav__wrapper').toggleClass('expanded')
  $('body').toggleClass('locked')
}

onMounted(() => {
  if ($('.main-menu__list').length && $('.mobile-nav__container').length) {
    const navContent = document.querySelector('.main-menu__list').outerHTML
    const mobileNavContainer = document.querySelector('.mobile-nav__container')
    mobileNavContainer.innerHTML = navContent
  }
  if ($('.mobile-nav__container .main-menu__list').length) {
    const dropdownAnchor = $(
      '.mobile-nav__container .main-menu__list .dropdown > a'
    )
    dropdownAnchor.each(function () {
      const self = $(this)
      const toggleBtn = document.createElement('BUTTON')
      toggleBtn.setAttribute('aria-label', 'dropdown toggler')
      toggleBtn.className = 'toggleBtnCls'
      toggleBtn.innerHTML = "<i class='bi bi-caret-down-fill'></i>"
      self.append(function () {
        return toggleBtn
      })
      self.find('#showSearch').on('click', function () {
        showSearchModel.value = true
      })
      self.find('button').on('click', function (e) {
        e.preventDefault()
        const self = $(this)
        self.toggleClass('expanded')
        self.parent().toggleClass('expanded')
        self.parent().parent().children('ul').slideToggle()
      })
    })
  }
  if ($('.mobile-nav__toggler').length) {
    $('.mobile-nav__toggler').on('click', function (e) {
      e.preventDefault()
      $('.mobile-nav__wrapper').toggleClass('expanded')
      $('body').toggleClass('locked')
    })
  }
})
const toplink = ref(null)
onMounted(async () => {
  const result = await getIndex()
  toplink.value = result.toplink
})

</script>

<style lang="stylus" scoped>
.hot-line
  height 46px
  background #209BD7
  line-height 46px
  padding-right 100px
  font-size 12px
  color #fff
  font-family: SimSun;
  @media screen and (max-width 768px) {
    padding-right 30px
  }
  &  > span
    margin-right 30px
.bi-chevron-down
  // transition all .3s ease
.router-link-active
  color rgba(32, 155, 215, 1) !important
  transition: all .5s ease-in-out 0s !important;
  @media screen and (max-width 576px) {
    color #fff !important
  }
  .bi-chevron-down
    display inline-block
    transform: rotate(180deg) !important;
.main-menu .main-menu__list li ul li a
  color #333 !important
.main-menu-wrapper
  border-left 1px solid #e5e5e5
  border-right 1px solid #e5e5e5
  padding 0 30px
  @media screen and (max-width 768px) {
    border none
  }
.main-menu-wrapper__logo
  img
    display block
    margin 0 auto
.main-menu-wrapper__main-menu
  width 100%
.main-menu__list
  width 100%
  @media (min-width: 1200px){
    align-items center
    display flex
    justify-content space-around
  }
  .dropdown
    & > a
      display block
      padding-left 20px
      padding-right 20px
      @media screen and (max-width 768px){
        padding 0
        display flex
      }
      @media screen and (max-width 1550px) and (min-width 1200px){
        padding 0
      }
.mobile-nav__toggler
  position absolute
  right 0px
  padding 20px 0 !important
.main-menu-wrapper__call-number
  cursor pointer
.mobile-nav__content
  .bi-chevron-down
    display none !important
/deep/.mobile-search
  .toggleBtnCls
    display none !important
.toplink{
  position relative
  z-index 1000
  cursor pointer
  a{
    text-decoration none
    color #fff
  }
}
</style>
